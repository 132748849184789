import * as React from 'react';
import map from 'lodash/map';
import Heading from './heading';
import Text from './text';
import './wedding-party.scss';

const data = {
    title: 'Wedding Party',
    bridesmaids: [
        {
            name: 'Morgan Ermenio',
            role: 'Maid of Honor',
            bio:
                'Morgan is my sister and my best friend. I’m lucky that those two words mean the same thing! Morgan is loyal, joyful, energetic and spunky. To have grown up with her is one of my greatest blessings in life. We’ve been known to stay up late, jump on the bed, giggle in inappropriate situations, and make parody videos on Youtube. Don’t try to look it up, we’ve already requested that Youtube take it down.',
        },
        {
            name: 'Shailagh Lannon',
            role: 'Bridesmaid',
            bio:
                'Shai and I became roommates back in 2018 when we were both teachers in Tulsa working with the Teach For America program. While we weren’t completely random roommates, we were pretty dang close. I was worried about if we would get along or not, and God had to be laughing at me. Shai has not only been the best roommate I’ve ever had, but the best friend I’ve ever had. She is endlessly loving, generous, kind, and compassionate. I’m convinced that we were both drawn to Tulsa so our paths would cross. We are twin flames that burn brighter together!',
        },
        {
            name: 'Lauren Vogelsang',
            role: 'Bridesmaid',
            bio:
                'Lauren and I first met through Ignite texas at UT Austin - I actually met her the same day that I met Andrew! I still remember the exact booth we sat in when we got to know each other over dinner at Madam Mam’s on The Drag back in 2016 and ended up chatting for hours. After that, we ended up rooming together our senior year in college, where we had so much fun hosting our monthly dinner parties and having wine + movie nights. Lauren is someone who loves to laugh and loves to give freely to others. I’m grateful to call her a friend!',
        },
        {
            name: 'Lily Hor',
            role: 'Bridesmaid',
            bio:
                'Name a better duo...we’ll wait. Lily and I were matched as a big-little pair in Texas Bluebonnets at UT Austin in 2015, and it was a match made in heaven. We soon began doing everything together, from meals, to working out, to double dates (until I met Andrew, of course). Even though Lily transferred to Texas A&M the next year, I’m glad to say that we kept in touch and have grown up together over the years!',
        },
        {
            name: 'Izzy Lee',
            role: 'Bridesmaid',
            bio:
                'Izzy and I met in the spring of our freshman year at UT Austin when we both joined Texas Bluebonnets the same semester. She quickly became someone I could count on and confide in! We bonded over many meals at Pluckers and Juiceland. Izzy has always been someone that has helped me grow in my faith and in my potential over the years. She gently challenges me to be the best version of myself and has been yet another great friend I’ve made through Bluebonnets!',
        },
        {
            name: 'Nicole Walker',
            role: 'Bridesmaid',
            bio:
                'Nicole was one of my first friends in college that I met in the bathroom at Jester East dormitory. Community bathrooms change lives, y’all! This friendship quickly blossomed when we both joined Texas Bluebonnets at UT Austin (noticing a trend here). Nicole and I went to all our social events together and spent countless weekends going to football games and each other’s homes, mostly for wine. Our girl’s nights are some of my most treasured memories from college!',
        },
        {
            name: 'Sarah Villarreal',
            role: 'Bridesmaid',
            bio:
                'Sarah and I led a Bible study together through the Austin Stone, and the rest is history! Sarah is goofy, tenacious, driven and genuine - she is such an amazing friend in my life. She has always been vulnerable and open, encouraging me to be bold like she is. Keeping up with her over the years has been natural, and I always love getting to see her back in the motherland of Austin!',
        },
        {
            name: 'Maya Duna-Barger',
            role: 'Bridesmaid',
            bio:
                'Maya is my bonus sister, and I could not be happier about that! Maya is wise, kind, fashionable, and we have so much in common with one another. I always love our outings in Houston and the time we get to spend together at Duna family reunions. She reminds me of Andrew in a lot of ways - gentle, loving and a great chef (confession: it was me who ate the majority of her peanut butter snickerdoodles at Thanksgiving). So Maya, when are you moving to Dallas so we can live out the sister dream?',
        },
    ],

    groomsmen: [
        {
            name: 'Mark Duna',
            role: 'Best Man',
            bio:
                'Mark, my brother, my friend, and my enemy. The bro-ly trinity, if you will. Mark is incredibly athletic, unbelievably smart, and funny at exactly the right moments. Despite our brotherly rivalry (and him beating me in pretty much everything), I am truly inspired by him – that is, inspired to work out until my physique far surpasses his.',
        },
        {
            name: 'Stuart Cornett',
            role: 'Groomsman',
            bio:
                'When it comes to the title of best friend, Stuart is the reigning champion. We became close friends in high school, went long-distance when he moved to Tulsa (sound familiar?), and now we live just down the street from each other in Dallas. Stuart is adventurous, imaginative, easygoing, and when we combine our chef-skills you’ll not only have a good time, but a delicious shepherd’s pie.',
        },
        {
            name: 'Drew Hendricks',
            role: 'Groomsman',
            bio:
                'Drew and I met in high school band, and very quickly became close friends. And if you needed more reasons to call us nerds: our early friendship blossomed over Mario Kart, the Avengers, and Chinese class. A testament to his welcoming and outgoing nature, Drew’s house was always the go-to hang out spot, and when moved up north for "uni" he became my excuse to visit Canada. He’s  clever, outdoorsy, energetic, and what some would call a “real hoot.”',
        },
        {
            name: 'Christian Roper',
            role: 'Groomsman',
            bio:
                'Christian Roper was my college roommate for three years. In addition to being a creative inspiration, he is the source of many of my life’s shenanigans (for example: live-streaming ourselves watching Chicken Little while eating KFC Chicken Littles). Christian has spent the last few years producing a documentary about lost treasure in East Texas – he has yet to realize that the real treasure was inside him all along.',
        },
        {
            name: 'Tim Barber',
            role: 'Groomsman',
            bio:
                'Tim was the leader for the Ignite team where Maddy and I met, and one of the first to see our relationship bloom. He and I are notorious for spending hours at coffee shops pretending to study, letting our conversations last well into the night, and somehow always ending up at Raising Cane’s. Tim is extremely caring, deeply invested in his friendships, and is a bright light in the lives of many!',
        },
        {
            name: 'Christian Barham',
            role: 'Groomsman',
            bio:
                'Christian Barham is the tallest person I know, both in height and in love. He and I co-led a Bible study and worked together on Ignite’s media team. The summer after graduating, we went on an iconic trip to Nashville, filled with lots of driving, plenty of laughs, and delicious hot chicken. He’s intelligent, passionate, and his puns are guaranteed to either make you laugh or start a fight.',
        },
        {
            name: 'Matt Freeman',
            role: 'Groomsman',
            bio:
                'Matt is that friend who is absolutely hilarious, but has no idea. He and I met in college, and frequently gather for dinner, drinks, and a movie (a classic date if you ask me). He is one of the kindest and friendliest people I know, and although he’s just a town over in Fort Worth, it’s still too much distance.',
        },
        {
            name: 'Derek Barger',
            role: 'Groomsman',
            bio:
                'Derek (a.k.a. Maya’s husband) is my extra brother, and an exciting addition to the Duna family! Witnessing my sister’s relationship with him has been a beautiful picture of marriage and joy. Also, Derek, like myself, is a huge nerd! Our frequent topics of conversation include board games, clicky keyboards, and teaching me how to unicycle (I tried once, it did not go well).',
        },
    ],
};

const PartyMember = ({ member }) => {
    return (
        <li>
            <Heading level={4} size={6}>
                <b>{member.name}</b> – <i>{member.role}</i>
            </Heading>
            <Text size="small">{member.bio}</Text>
        </li>
    );
};

const PartyGroup = ({ title, group }) => {
    return (
        <>
            <Heading level={3} size={2}>
                {title}
            </Heading>
            <ul className="wedding-party--group">
                {map(group, (member) => (
                    <PartyMember member={member} key={member.name} />
                ))}
            </ul>
        </>
    );
};

// markup
const WeddingPartyPage = () => {
    return (
        <div className="wedding-party">
            <Heading level={2} size={1}>
                {data.title}
            </Heading>
            <PartyGroup title="Bridesmaids" group={data.bridesmaids} />
            <PartyGroup title="Groomsmen" group={data.groomsmen} />
        </div>
    );
};

export default WeddingPartyPage;

// query all images
