import * as React from 'react';
import Heading from '../components/heading';
import Layout from '../components/layout';
import Text from '../components/text';
import AnimateIn from '../components/animate-in';
import SEO from '../components/seo';
import './wedding.scss';
import WeddingPartyPage from '../components/wedding-party';

const data = {
    title: 'The Wedding',
    date: 'Saturday, October 23rd, 2021',
    time: 'Ceremony begins at 5:00pm – dinner and reception to follow',
    venueName: 'The Cliff House',
    venueAddress: '610 N Tyler Street, Dallas, TX 75208',
};

const DetailsItem = ({ label, text, subtext }) => {
    return (
        <div className="wedding--detail-item">
            <Heading level={2} size={5} className="label">
                {label}
            </Heading>
            <Heading level={3} size={2}>
                {text}
            </Heading>
            <Text size="medium" className="subtext">
                {subtext}
            </Text>
        </div>
    );
};

// markup
const WeddingPage = () => {
    return (
        <Layout className="wedding-page">
            <SEO title="Wedding" slug="wedding" />
            <AnimateIn onScroll>
                <div>
                    <Heading level={1}>{data.title}</Heading>
                    <div className="wedding--details">
                        <DetailsItem
                            label="Date &amp; Time"
                            text={data.date}
                            subtext={data.time}
                        />
                        <DetailsItem
                            label="Venue"
                            text={data.venueName}
                            subtext={data.venueAddress}
                        />
                    </div>
                </div>

                <WeddingPartyPage />
            </AnimateIn>
        </Layout>
    );
};

export default WeddingPage;
